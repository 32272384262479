@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  html {
    height: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .login-box,
  .register-box {
    margin: auto;
  }

  .login-box-msg,
  .register-box-msg {
    margin-top: -7px;
    text-align: center;
    padding: 0 20px 3px 5px;
    font-size: 17px;
  }

  .login-box-body,
  .register-box-body {
    background: #fff;
    padding: 20px;
    padding-bottom: 26px;
    border-top: 0;
    color: #666;
  }

  .skin-blue .main-header .navbar .nav > li > a {
    color: black;

    &:hover {
      color: #3a3a3a;
    }
  }

  .main-header .logo .logo-lg {
    filter: invert(100%);
  }

  .main-header .logo .logo-mini {
    filter: invert(100%);
  }

  .skin-blue .main-header {
    .navbar,
    .logo {
      background-color: #fbf02f;

      .sidebar-toggle {
        color: black;

        &:hover {
          color: #b6b37c;
        }
      }
    }

    .logo:hover,
    .navbar .sidebar-toggle:hover {
      background: #ede43ef5;
    }
  }

  .skin-blue .main-header li.user-header {
    background-color: #585e63;
  }

  .skin-blue .main-header .navbar .nav > li > a:hover,
  .skin-blue .main-header .navbar .nav > li > a:active,
  .skin-blue .main-header .navbar .nav > li > a:focus,
  .skin-blue .main-header .navbar .nav .open > a,
  .skin-blue .main-header .navbar .nav .open > a:hover,
  .skin-blue .main-header .navbar .nav .open > a:focus,
  .skin-blue .main-header .navbar .nav > .active > a {
    background-color: #ede43ef5;
    color: #000;
  }

  .row {
    margin-right: -15px;
    margin-left: -15px;
    padding-top: 10px;
    margin-top: 0px;

    .btn-primary {
      background-color: #292929;
      border: 0;
      font-size: 18px;
      padding: 2px 0px 1px 0;
      margin-top: 9.5px;
    }

    .pull-right .btn-AlterPassword {
      background-color: #292929;
      border: 0;
      font-size: 18px;
      padding: 2px 6px 0px 9px;
      margin-top: 2.5px;
    }

    a {
      color: #666;
      font-size: 13px;
    }

    .radio,
    .checkbox {
      margin: 10px 0 10px -15px;
    }

    .checkbox label,
    .radio label {
      font-weight: 400;
      font-size: 18px;
    }

    .col-xs-4 {
      width: 31%;
    }

    .btn-primary:hover,
    .btn-primary:active,
    .btn-primary.hover {
      background-color: #666;
    }

    .alter-password {
      background-color: #8ce8a2;
      border: 0;
      font-size: 16px;
      padding: 2px 5px 1px 6px;
      color: #fff;
      transition: 0.2s;
      border: 1px solid #fff;
      border-radius: 5px;

      &:hover {
        background-color: #9cddab;
      }
    }

    .alter-btnFiles {
      background-color: #8ce8a2;
      color: white;
      transition: 0.2s;
      line-height: 16px;
      font-size: 13.5px;

      &:hover {
        color: whitesmoke;
      }
    }

    .alter-btnSubBrandSkul {
      background-color: whitesmoke;
      border: 1px solid whitesmoke;
      font-size: 16px;
      padding: 2px 5px 1px 6px;
      color: black;
      border-radius: 5px;
      transition: 0.2s;

      &:hover {
        background-color: #e2e2e2;
      }
    }

    .box-header .alter-btnSelect {
      display: inline-block;
      font-size: 16px;
      margin: 0;
      line-height: 1;
    }

    .box-header .alter-subBrand {
      background-color: whitesmoke;
      border: 1px dotted whitesmoke;
      font-size: 16px;
      padding: 2px 5px 1px 6px;
      color: black;
      border-radius: 5px;
      transition: 0.2s;

      &:hover {
        background-color: #e2e2e2;
      }
    }

    .box-footer {
      .comment {
        background-color: whitesmoke;
        border: 1px dotted whitesmoke;
        font-size: 16px;
        padding: 2px 5px 1px 6px;
        color: black;
        transition: 0.2s;

        &:hover {
          background-color: #e2e2e2;
        }
      }

      .timeEdit {
        background-color: whitesmoke;
        border: 1px dotted whitesmoke;
        font-size: 16px;
        padding: 2px 5px 1px 6px;
        color: black;
        transition: 0.2s;

        &:hover {
          background-color: #e2e2e2;
        }
      }

      .timeSubmit {
        background-color: #8ce8a2;
        border: 1px solid whitesmoke;
        font-size: 16px;
        padding: 2px 5px 1px 6px;
        color: #fff;
        transition: 0.2s;

        &:hover {
          background-color: #9cddab;
        }
      }
    }

    .alter-btnSubBrand {
      color: #fff;
      transition: 0.2s;
      background-color: #8ce8a2;

      &:hover {
        color: whitesmoke;
      }
    }

    .alter-subBrandReOpen {
      background-color: whitesmoke;
      border: 1px dotted whitesmoke;
      font-size: 16px;
      padding: 2px 5px 1px 6px;
      color: black;
      border-radius: 5px;
      margin-top: 3px;
      transition: 0.2s;

      &:hover {
        background-color: #e2e2e2;
      }
    }

    .table-bordered > thead > tr > th,
    .table-bordered > thead > tr > td,
    .table-bordered > tbody > tr > th,
    .table-bordered > tbody > tr > td,
    .table-bordered > tfoot > tr > th,
    .table-bordered > tfoot > tr > td {
      border: 2px solid #f5f5f5;
    }

    .dataTables_scroll .dataTables_scrollBody {
      overflow: hidden;
      padding-bottom: 8px;
    }

    .text-blue {
      color: #976dee !important;
    }

    .CloseJobSubBrand {
      padding-top: 20px;
      margin-bottom: 20px;
    }

    .Indexjobs {
      padding-right: 5px;
      text-decoration: none;
    }

    .Comapanies {
      padding-right: 5px;
      text-decoration: none;
    }
  }

  .skin-blue .sidebar-menu > li.header {
    color: #ffffff;
    background: #000000;
    padding-top: 15px;
    margin-bottom: 5px;
  }

  .skin-blue .main-sidebar,
  .skin-blue .left-side {
    background-color: #2a2a2a;
  }

  .skin-blue .sidebar-menu > li:hover > a,
  .skin-blue .sidebar-menu > li.active > a {
    color: #fff;
    background: #3e3e3e;
    border-left-color: #d7d7d7;
  }

  // .skin-blue .sidebar-menu > li.active > a {
  //     color: #fff;
  //     background: #3e3e3e;
  //     border-left-color: #aeaeae;
  // }

  .bg-red,
  .callout.callout-danger,
  .alert-danger,
  .alert-error,
  .label-danger,
  .modal-danger .modal-body {
    background-color: #fc2f34 !important;
  }

  .bg-green,
  .callout.callout-success,
  .alert-success,
  .label-success,
  .modal-success .modal-body {
    background-color: #8ce8a2 !important;
  }

  .item-transfer {
    background-color: #ededee !important;
  }

  .full-center {
    position: absolute;
    width: 100%;
    top: 20%;
  }

  .logo-lg,
  .logo-mini {
    img {
      max-width: 100%;
    }
  }

  .login-logo {
    background-color: #000;
    margin-bottom: 0;
    padding-top: 18px;
    padding-bottom: 13.5px;
    font-size: 0;
  }

  .reset-pass {
    display: block;
    color: #fff;
    line-height: 1.1;
    margin-top: 14px;
    font-size: 25px;
    padding-top: 23px;
    text-decoration: none;
    text-transform: uppercase;
  }

  .top-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    background-color: #f6f6f6;

    h1 {
      font-size: 14px;
      text-align: center;
      margin: 0;
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }

  .site-name {
    text-transform: uppercase;
  }

  .login-page {
    background-color: #000;
  }

  .background-load {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: none;

    img {
      min-width: 100%;
      min-height: 100%;
      max-width: 100%;
    }
  }

  .box.box-primary {
    border-top-color: #666;
  }

  .timeline-footer {
    .alter-btnEditComment {
      background-color: #000;
      border: 1px solid whitesmoke;
      font-size: 16px;
      padding: 3px 5px 2px 6px;
      color: white;
      transition: 0.2s;

      &:hover {
        background-color: rgb(60, 60, 60);
      }
    }

    .btn-alterDelete {
      background-color: #fc2f34;
      border-color: #d73925;
      line-height: 23.5px;
      font-size: 15px;
      transition: 0.2s;
      margin-top: 10px;
      padding: 2px 5px 2px 6px;

      &:hover {
        background-color: #d55949;
      }
    }

    .btn-alterWarning {
      background-color: #fbf02f;
      border-color: #fbf02f;
      color: white;
      line-height: 25px;
      margin-top: 10px;
      font-size: 14px;

      &:hover {
        background-color: #f8f04e;
      }
    }

    .ApprovedClient {
      line-height: 23.5px;
      font-size: 15px;
      color: white;
      transition: 0.2s;
      margin-top: 10px;

      &:hover {
        color: #e8e8e8;
      }
    }

    .DisapprovedClient {
      line-height: 23.5px;
      font-size: 15px;
      color: white;
      transition: 0.2s;
      margin-top: 10px;

      &:hover {
        color: #e8e8e8;
      }
    }
  }

  .content-header > .breadcrumb > li > a {
    color: #444;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
  }

  .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice {
    background-color: lightslategray;
    border-color: #fff;
    padding: 1px 10px;
    color: #fff;
  }

  .pagination > .active > a,
  .pagination > .active > a:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span,
  .pagination > .active > span:hover,
  .pagination > .active > span:focus {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #818b95;
    border-color: #fff;
  }

  .bg-blue {
    background-color: #e3d623 !important;
  }

  .select2-container--default
    .select2-results__option--highlighted[aria-selected] {
    background-color: lightslategray;
    color: white;

    &:hover {
      color: #fff;
    }
  }

  .navbar-nav > .user-menu > .dropdown-menu > li.user-header > img {
    z-index: 5;
    height: 90px;
    width: 90px;
    border: 0px solid;
    border-color: transparent;
    border-color: rgba(255, 255, 255, 0.2);
  }

  .background-loadError {
    .imageError {
      position: fixed;
      top: 0;
      left: 0;
      min-width: 100%;
      min-height: 100%;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-user-drag: none;
      user-drag: none;
      -webkit-touch-callout: none;
      width: 100%;
      height: 100%;
    }
  }

  .background-ButtonError {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 8vh;

    .ReturnPageError {
      display: flex;
      min-width: 200px;
      font-weight: 700;
      text-transform: uppercase;
      border: 0;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease;
      border-radius: 8px;
      position: relative;
      overflow: hidden;
      outline: none;
      padding: 0 25px;
      color: #004588;
      height: 36px;
      font-size: 12px;
      background: white;
      cursor: pointer;
      font-family: "Raleway", sans-serif;
      text-decoration: none;

      &:hover {
        color: #4e7ca8;
      }
    }
  }

  // Fix Errors - Glyphicons Halflings not found
  @font-face {
    font-family: "Glyphicons Halflings";
    src: url("/sp-fonts/glyphicons-halflings-regular.eot");
    src: url("/sp-fonts/glyphicons-halflings-regular.eot?#iefix")
        format("embedded-opentype"),
      url("//maxcdn.bootstrapcdn.com/bootstrap/3.3.7/fonts/glyphicons-halflings-regular.woff2")
        format("woff2"),
      url("/sp-fonts/glyphicons-halflings-regular.woff") format("woff"),
      url("/sp-fonts/glyphicons-halflings-regular.ttf") format("truetype"),
      url("/sp-fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular")
        format("svg");
  }

  @media (max-width: 768px) and (min-width: 320px) {
    .main-header .logo .logo-mini {
      filter: invert(100%);
    }

    .row {
      .pull-right .btn-AlterPassword {
        top: -7px;
      }
    }

    .btn.btn-flat {
      position: relative;
      display: ruby-base-container;
    }

    .sidebar-mini.sidebar-collapse .main-header .navbar {
      margin-left: 50px;
    }

    .background-load {
      img {
        width: auto;
        height: 100%;
        max-width: inherit;
      }
    }
  }

  @media (min-width: 700px) and (max-width: 980px) {
    .background-loadError {
      .imageError {
        margin-left: -859px;
        width: auto;
        background: url("public/assets/img/backgroundError/2.webp") fixed;
      }
    }

    .background-ButtonError {
      height: 9vh;

      .ReturnPageError {
        border-radius: 13px;
        height: 44px;
        font-size: 16px;
        padding: 0 16px;
      }
    }
  }

  @media (min-width: 316px) and (max-width: 560px) {
    .background-loadError {
      .imageError {
        margin-left: -766px;
        width: auto;
        background: url(/assets/img/backgroundError/2.webp) fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
      }
    }

    .background-ButtonError {
      height: 9vh;

      .ReturnPageError {
        border-radius: 13px;
        height: 40px;
        font-size: 16px;
        padding: 0 16px;
      }
    }
  }

  tbody > tr > td {
    cursor: pointer;
  }

  .wrapper-fields-job-express {
    display: none;
  }

  .select2,
  .select2-allow-clear {
    width: 100%;
  }

  //TablesResponsiveScroll
  #usersTable,
  #sendFilesTable,
  #brandsTable,
  #projectsTable,
  #companiesTable {
    width: 100%;

    tr {
      td {
        min-width: 155px;
        text-align: left;
      }
    }
  }

  .overflow-hidden {
    overflow: hidden !important;
  }

  .image-profile {
    max-width: 100%;
    width: 50px;
    height: 50px;
  }
}
